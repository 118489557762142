<template>
  <Template
    v-bind="{
      ...params,
      isLoading,
    }"
    @onSubmit="() => doneClicked()"
    @goTo="(path) => $router.push(path)"
    @onBack="() => $router.go(-1)"
    @goHome="() => $router.push('/')"
  />
</template>
<script>
import Template from "../components/templates/NewPassword/NewPassword";
import { c } from "@/components/constants.js";

export default {
  components: {
    Template,
  },
  methods: {
    doneClicked: async function () {
      const forms = this.params.form.forms;
      await this.actions.user.passwordReset({
        email: this.$route.params.email,
        password: forms[0]?.value,
        resetToken: this.$route.params.token,
      });
      this.actions.alert.showSuccess({
        message: "Updated password successfully! Please login again.",
      });
      this.$router.push("/login");
    },
  },
  data() {
    return {
      params: c.newPassword,
      isLoading: false,
    };
  },
};
</script>
